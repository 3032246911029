import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/learn',
    name: 'Learn',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Learn.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/FAQ.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/license',
    name: 'License Agreements',
    component: () => import('../views/License.vue')
  },    
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/credits',
    name: 'Credits',
    component: () => import('../views/Credits.vue')
  },
  {
    path: '/releases',
    name: 'What\'s New',
    component: () => import('../views/Releases.vue')
  },
  {
    path: '/' +
        'research',
    name: 'Research',
    component: () => import('../views/Research.vue')
  },
]
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history to maintain old URLs.
  // TODO: switch to createWebHistory() for better SEO support and to remove the hash tags from URLs
  history: createWebHashHistory(),
  routes,
  // scroll to top of page on route change
  scrollBehavior () {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  }
})

router.afterEach((to) => {
  document.title = to.name + " - EarSketch";
})

export default router
