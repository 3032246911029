import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag"
import 'highlight.js/styles/github.css'

const app = createApp(App)

app.use(router)

if(process.env.NODE_ENV !== "development") {
    app.use(VueGtag, {
        config: { id: "G-TEGJSB3QNV" }
    }, router)
}

app.mount('#app')
