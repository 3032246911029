<template>
  <div>
    <div class="top" v-if="wrapped">
      <div class="top-menu">
        <div class="logo-corner">
          <router-link to="/" class="logo"></router-link>
          <router-link to="/" class="esname">EarSketch</router-link>
        </div>
        <router-link to="/learn" class="menubtn menu-learn">LEARN</router-link>
        <a href="https://www.teachers.earsketch.org" target="_blank" class="menubtn menu-teach">TEACH</a>
        <a href="https://www.teachers.earsketch.org/compete" target="_blank" class="menubtn menu-competition">COMPETITION</a>
        <router-link to="/about" class="menubtn menu-about-us">ABOUT US</router-link>
        <router-link to="/faq" class="menubtn menu-faq">FAQ</router-link>
        <router-link to="/contact" class="menubtn menu-contact">CONTACT</router-link>
        <a @click="$emit('startCoding')" href="/earsketch2/" target="_blank" class="button menu-start-coding" id="orangebtn">START CODING</a>
      </div>
      <div class="top-title">
      <div v-if="message" class="middle-text">{{ message.toUpperCase() }}</div>
      <p v-if="subtitle" class="subtitle-text">
        Feel the constant <span class="code">makeBeat()</span> of innovation. Music and code in harmony.<br>
        Best enjoyed in an ergonomic chair with
        <span class="keep-together"><span class="code">
          {{selectedSound}}<button
            class="sound-preview-button"
            :class="{ 'play': !this.sound, 'stop': this.sound }"
            v-on:click="toggleSound(selectedSound)"
            title="play"
            aria-label="play"
          >
          <i class="icon" :class="{ 'icon-play4': !this.sound, 'icon-stop2': this.sound }" style="font-size: 1.5em" />
        </button>
      </span>.</span>
    </p>
    </div>
  </div>
  <div class="top-menu" v-if="!wrapped">
    <div class="logo-corner">
      <router-link to="/" class="logo"></router-link>
      <router-link to="/" class="esname">EarSketch</router-link>
    </div>
    <router-link to="/learn" class="menubtn menu-learn">LEARN</router-link>
    <a href="https://www.teachers.earsketch.org" target="_blank" class="menubtn menu-teach">TEACH</a>
    <a href="https://www.teachers.earsketch.org/compete" target="_blank" class="menubtn menu-competition">COMPETITION</a>
    <router-link to="/about" class="menubtn menu-about-us">ABOUT US</router-link>
    <router-link to="/faq" class="menubtn menu-faq">FAQ</router-link>
    <router-link to="/contact" class="menubtn menu-contact">CONTACT</router-link>
    <a @click="$emit('startCoding')" href="/earsketch2/" target="_blank" class="button orangebtn menu-start-coding">START CODING</a>
  </div>
</div>
</template>

<script>
export default {
  name: 'Nav',
  props: {
    message: String,
    wrapped: {
      type: Boolean,
      default: true
    },
    subtitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sound: undefined,
      sounds: [
          "RD_UK_HOUSE_MAINBEAT_8",
          "RD_POP_SYNTHBASS_6",
          "YG_RNB_TAMBOURINE_1",
          "YG_FUNK_CONGAS_3",
          "YG_FUNK_HIHAT_2",
          "RD_POP_TB303LEAD_3",
          "DUBSTEP_BASS_WOBBLE_002",
          "RD_FUTURE_DUBSTEP_BASSWOBBLE_20",
          "RD_FUTURE_DUBSTEP_SFX_4",
          "Y07_WAH_GUITAR",
          "Y11_PIANO_1",
          "RD_RNB_ACOUSTIC_NYLONSTRING_4",
          "YG_FUNK_ELECTRIC_PIANO_1",
          "MILKNSIZZ_AFROSENSE_PIANO_TWINKLE",
          "MILKNSIZZ_ADIOS_GUITAR",
          "IRCA_LATIN_JAZZ_1_PIANO_SOLO",
      ]
    }
  },
    computed: {
      selectedSound() {
          return this.sounds[Math.floor(Math.random() * this.sounds.length)]
      }
    },
  methods: {
    async toggleSound(sound) {
      if (this.soundSource === undefined || this.sound !== sound) {
        this.playSound(sound)
      } else {
        this.stopSound()
      }
    },
    async playSound(sound) {
        this.$gtag.event('play_sound')
      if (this.sourceSource !== undefined) {
        this.stopSound()
      }
      this.sound = sound
      const soundUrlPrefix = "https://api.ersktch.gatech.edu/EarSketchWS/audio/sample?name="
      // TODO: Move this to somewhere appropriate for initialization in Vue.
      const context = new AudioContext()
      const gain = new GainNode(context, { gain: 0.5 })
      gain.connect(context.destination)
      // TODO: Check if the browser caches this (and cache here if needed).
      const data = await (await fetch(soundUrlPrefix + sound)).arrayBuffer()
      const audioBuffer = await context.decodeAudioData(data)
      this.soundSource = context.createBufferSource()
      this.soundSource.buffer = audioBuffer
      this.soundSource.connect(gain)
      this.soundSource.start(context.currentTime)
      this.soundSource.onended = () => this.stopSound()
    },
    async stopSound() {
      this.soundSource?.stop()
      this.soundSource?.disconnect()
      this.soundSource = undefined
      this.sound = undefined
    }
  }
}
</script>

<style>
@import '../assets/fonts/icomoon_ultimate/style.css';
</style>
