<template>
  <div id="app" class="mainpage">

    <router-view/>

    <div class="footer">
      <div class="footer-link-grid">
        <div>
          <div class="footer-link-heading">About</div>
          <router-link to="/about"><div class="footer-link-table-elm">Our Team</div></router-link>
          <router-link to="/releases"><div class="footer-link-table-elm">What's New</div></router-link>
          <router-link to="/credits"><div class="footer-link-table-elm">Credits</div></router-link>
        </div>
        <div>
          <div class="footer-link-heading">Resources</div>
          <router-link to="/faq"><div class="footer-link-table-elm">FAQ</div></router-link>
          <router-link to="/learn"><div class="footer-link-table-elm">Learn</div></router-link>
          <a href="https://www.teachers.earsketch.org/" target="_blank"><div class="footer-link-table-elm">EarSketch Teachers ⧉</div></a>
          <a href="https://www.teachers.earsketch.org/compete" target="_blank"><div class="footer-link-table-elm">Your Voice is Power ⧉</div></a>
        </div>
        <div>
          <div class="footer-link-heading">Publications</div>
          <router-link to="/research"><div class="footer-link-table-elm">Research</div></router-link>
          <a href="https://www.teachers.earsketch.org/newsroom" target="_blank"><div class="footer-link-table-elm">Media ⧉</div></a>
        </div>
        <div>
          <div class="footer-link-heading">Legal</div>
          <router-link to="/license"><div class="footer-link-table-elm">License Agreement</div></router-link>
          <router-link to="/privacy"><div class="footer-link-table-elm">Privacy Policy</div></router-link>
        </div>
        <div>
          <div class="footer-link-heading">Reach Out</div>
          <router-link to="/contact"><div class="footer-link-table-elm">Contact</div></router-link>
          <a href="https://twitter.com/earsketch/" target="_blank"><div class="footer-link-table-elm">Twitter ⧉</div></a>
        </div>
      </div>
      <div>
        <div class="footer-centered-container">
          <a class="gt-link" href="https://www.gatech.edu/" target="_blank">A project from Georgia Tech</a>
        </div>
      </div>
    </div>

  </div>
</template>

<style>
@import 'assets/styles/main.css';
</style>
