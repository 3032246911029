<template>
  <div class="home">
    <div class="home-top">
      <Nav @start-coding="clickedStartCoding('nav')" class="homenav" v-bind:wrapped="false"></Nav>

      <div class="home-middle-text">
        MAKE BEATS.<br>
        LEARN CODE.
      </div>
      <div class="middle-buttons">
        <router-link to="/learn" class="button middle-learn" id="whitebtn">START LEARNING</router-link>
        <a @click="clickedStartCoding('home-middle')" href="/earsketch2/" target="_blank" class="button middle-code" id="orangebtn">START CODING</a>
      </div>
    </div>

    <div class="teacherbar">
      <div class="teacher-text"><span id="normal-text">Are you a teacher? Get free teaching materials!</span><span id="small-text">Are you a teacher?</span></div>
      <a href="https://www.teachers.earsketch.org" target="_blank" class="button learnmore-button" id="clearblackbtn"><span id="normal-button">LEARN MORE</span><span id="small-button">LEARN MORE</span></a>

    </div>
    <div class="middleinfo">
      <video class="video" controls poster="../assets/vid/homepagevid-poster.png">
        <source src="../assets/vid/homepagevid.mp4" type="video/mp4">
      </video> 
      <div class="helps-you-container">
        <div class="helps-you-text">
        <div class="helps-you-header">EarSketch helps you</div>
        <ul class="helps-you-bullets">
          <div class="bullets-container">
            <li>Learn coding through music </li>
            <li>Use our sounds or your own </li>
            <li>Learn Python or JavaScript code </li>
            <li>Produce studio-quality music</li></div></ul>
        </div>
        <a @click="clickedStartCoding('helps-you')"  href="/earsketch2/" target="_blank" class="button middle-start-coding" id="opaqueblackbtn">START CODING</a>
      </div>
    </div>
    <div class="iconsbar"></div>
<!--    <div v-if="windowwidth > 632">-->
<!--      <div class="carousel">-->
<!--        <div id="header">Hear what students have created</div>-->
<!--        <a class="button see-all-button" href="https://soundcloud.com/escomp-ceismc" target="_blank" id="clearblackbtn">SEE MORE STUDENT WORK</a>-->
<!--        <div class="interactive-carousel">-->
<!--          <div>-->
<!--            <carousel-3d :controls-visible="true" :controls-prev-html="'&#10092;'" :controls-next-html="'&#10093;'" :controls-width="30" :controls-height="60" :clickable="true" :autoplay="false" :display="7" :width="largestWidth" :height="largestWidth * 0.75" :perspective="0">-->
<!--              <slide v-for="(slide, i) in slides" :key="slide.trackId" :index="i">-->
<!--                <div class="embed-container">-->
<!--                  <span>-->
<!--                    <iframe scrolling="no" :src="'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/' + slide.trackId + '&color=%238c7c7c&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=true&sharing=false&download=false'"></iframe>-->
<!--                  </span>-->
<!--                </div>-->
<!--&lt;!&ndash;                <p>{{slide.trackName}}<br>{{slide.artistName}}</p>&ndash;&gt;-->
<!--              </slide>-->
<!--            </carousel-3d>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="bottom">
      <div class="bottom-text">Have more questions?</div>
      <div class="bottom-buttons">
        <router-link to="/faq" class="button bottom-faq" id="clearblackbtn">FREQUENTLY ASKED QUESTIONS</router-link>
        <router-link to="/contact" class="button bottom-contact" id="bottomopaqueblackbtn">CONTACT US</router-link>
      </div>
    </div>
    <div class="funding">
      <p>
        EarSketch receives funding from the <a href="https://nsf.gov/" target="_blank">National Science Foundation</a> (#1138469, #1417835, #1504293, #1612644, #1741045, #1837661, #1814083, #1833520, #2005791, #2122499, and #2300631), the <a href="http://hudgensfoundation.org/" target="_blank">Scott Hudgens Family Foundation</a>, the <a href="https://blankfoundation.org/" target="_blank">Arthur M. Blank Family Foundation</a>, the Ruth L. Seigel Family Foundation, the <a href="https://edu.google.com/computer-science/educator-grants/#!?modal_active=none" target="_blank">Google Inc. Fund of Tides Foundation</a>, the <a href="https://provost.gatech.edu/news/new-gt-microsoft-accessibility-research-seed-grant-program-announces-winning-proposals-2021" target="_blank">GT-Microsoft Accessibility Research Seed Grant Program</a>, and <a href="https://www.amazonfutureengineer.com/" target="_blank">Amazon Future Engineer</a>.
      </p>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'

export default {
  data() {
    return {
      // Carousel stuff
      carouselon:true,
      slides: [
          {"trackId": 1246758817, "trackName": "", "artistName": ""}, {"trackId": 1246757356, "trackName": "", "artistName": ""}, {"trackId": 1246757092, "trackName": "", "artistName": ""}, {"trackId": 1246756246, "trackName": "", "artistName": ""}, {"trackId": 1246754908, "trackName": "", "artistName": ""}, {"trackId": 1246753336, "trackName": "", "artistName": ""}, {"trackId": 1246752943, "trackName": "", "artistName": ""}, {"trackId": 1246736584, "trackName": "", "artistName": ""}, {"trackId": 1246735873, "trackName": "", "artistName": ""}, {"trackId": 1246735267, "trackName": "", "artistName": ""}, {"trackId": 1246734685, "trackName": "", "artistName": ""}, {"trackId": 1246734160, "trackName": "", "artistName": ""}, {"trackId": 1246733671, "trackName": "", "artistName": ""}],
      largestWidth: window.innerWidth * 0.25,
      windowwidth: window.innerWidth,
    }
  },
    methods: {
      clickedStartCoding(location) {
          this.$gtag.event('start_coding', { location })
      }
    },
  //to dynamically resize the carousel based on the current width of the screen
  mounted() {
    window.addEventListener('resize', () => {
      this.largestWidth = window.innerWidth *0.25,
      this.windowwidth = window.innerWidth
    })
  },
  components: {
    Nav,
  }
}
</script>

